



















































import cambioscontrasenasperdidasModule from "@/store/modules/cambioscontrasenasperdidas-module";
import { Component, Vue } from "vue-property-decorator";
import { cambioscontrasenasperdidas } from "@/shared/dtos/cambioscontrasenasperdidas";
import { UtilsString } from "@/utils/utils-string";
@Component({ $_veeValidate: { validator: "new" } })
export default class pwdlost extends Vue {
  private objpwslost = new cambioscontrasenasperdidas();
  private ver_mensaje: boolean = false;
  private change_pwd_automatico=false;
  created() {
    if (UtilsString.IsValidParamUrl(this.$route.params.email)) {
      this.change_pwd_automatico=true;
      this.objpwslost.email = this.$route.params.email;
      setTimeout(() => this.submit(), 250);

    }
  }
  private submit() {
    this.objpwslost.url_production = window.location.href.replace(
      "lostpwd",
      ""
    );
    this.$validator.validateAll().then((allOk) => {
      if (allOk) {
        cambioscontrasenasperdidasModule
          .guardarcambioscontrasenasperdidas(this.objpwslost)
          .then(() => (this.ver_mensaje = true));
      }
    });
  }
}
